import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrReportFieldSelect from '@/modules/common/components/ui-core/gtr-report-field-select/gtr-report-field-select.vue'
import { ReportableEventField, ReportableEventFields } from '@/modules/common/models/reports.model'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrTrackEditReportView',
  computed: {
    ...mapState('report', ['report']),
    ...mapState('option', ['option_groups']),
    ...mapState('formbuilder', ['eventFields', 'reportableEventFields']),
    ...mapState('sessions', ['sessions'])
  },
  components: {
    'gtr-report-field-select': GtrReportFieldSelect
  }
})
export default class GtrTrackEditReportView extends GtrSuper {
  sessions!: Array<Record<string, any>>;
  report!: Record<string, any>;

  data () {
    return {
      current_step: 1,
      loading: true,
      submitting: false,
      sessionTable: {
        search: '',
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Category', align: 'center', sortable: true, value: 'category' },
          { text: 'Start', align: 'center', sortable: true, value: 'start_date' },
          { text: 'End', align: 'center', sortable: true, value: 'end_date' },
          { text: 'Scans', align: 'center', sortable: true, value: 'total_scans' },
          { text: 'Participants', align: 'center', sortable: true, value: 'unique_scans' },
          { text: 'Status', align: 'center', sortable: true, value: 'session_status' },
          { text: 'Scan Mode', align: 'center', sortable: true, value: 'scan_modes' },
          { text: 'Speaker', align: 'center', sortable: true, value: 'session_speakers' }
        ],
        selected: []
      },
      reportEdit: {
        name: null,
        module: 'SESSION_TRACKING',
        report_category: 'Attendance Tracking',
        report_type: 'SPREADSHEET',
        export_fields: [] as ReportableEventField[],
        reportFieldsOptions: [],
        criteria: {
          global_type: '',
          visible: true,
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        }
      },
      choices: {
        itemsFields: [],
        itemsGroups: [],
        reportFieldsParticipants: [] as ReportableEventField[],
        reportFieldsParticipantGroup: [] as ReportableEventField[],
        reportFieldsSessionAttendees: [] as ReportableEventField[],
        reportFieldsOptions: [] as ReportableEventField[],
        reportFieldsLeads: [] as ReportableEventField[],
        allFields: [] as ReportableEventField[],
        allOptions: [],
        allLeads: [] as ReportableEventField[],
        promoCodes: [] as ReportableEventField[]
      },
      selectAllSpreadsheet: false,
      fieldSelectionModalOpen: false,
      fieldSearchFilter: '',
      selectedFields: [] as ReportableEventField[],
      activeReportableEventFields: {} as ReportableEventFields
    }
  }

  async mounted () {
    this.$store.commit('report/SET_REPORT', {})
    await this.fetchData()
  }

  convertOldShowIfToNewFormat (payload: any) {
    const mergedShowIfObject = Object.assign({}, {
      global_type: payload.show_all ? '*' : '',
      visible: true,
      type: payload.show_all ? '*' : '',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }, payload.criteria)
    if (!payload.criteria) {
      return mergedShowIfObject
    }
    if (payload?.criteria?.show_all) {
      mergedShowIfObject.global_type = '*'
      mergedShowIfObject.type = '*'
    } else if (payload?.criteria?.type === 'grouping') {
      mergedShowIfObject.global_type = 'multiple'
    } else if (['field_criteria', 'option_criteria'].includes(payload?.criteria.type)) {
      mergedShowIfObject.global_type = 'single'
    }
    delete mergedShowIfObject.field_or_option
    delete mergedShowIfObject.show_if
    delete mergedShowIfObject.show_if_type
    return mergedShowIfObject
  }

  @Watch('option_groups')
  onOptionsGroupsChange (optionGroups: any[]) {
    this.$data.optionGroups = optionGroups
    const sortedOptionGroups: any[] = []
    optionGroups.forEach(option => {
      sortedOptionGroups.push({
        value: option.uuid,
        text: option.name
      })
    })
    sortedOptionGroups.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    this.$data.choices.itemsGroups = sortedOptionGroups
  }

  @Watch('reportableEventFields')
  onReportableEventFieldsChange (payload: any) {
    this.$data.choices.itemsFields = []

    // Fill reportFieldsSessionAttendees
    if (payload.session_attendees && (this.$data.activeReportableEventFields.session_attendees === undefined || this.$data.activeReportableEventFields.session_attendees.length === 0)) {
      this.$data.activeReportableEventFields.session_attendees = []
      for (const field in payload.session_attendees) {
        const fieldData = payload.session_attendees[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.session_attendees.push(itemData)
        this.$data.choices.itemsFields.push({
          text: fieldData.label || fieldData.field,
          value: fieldData.field
        })
      }
    }

    // Fill reportFieldsParticipants
    if (payload.participants && (this.$data.activeReportableEventFields.participants === undefined || this.$data.activeReportableEventFields.participants.length === 0)) {
      this.$data.activeReportableEventFields.participants = []
      for (const field in payload.participants) {
        const fieldData = payload.participants[field]
        if (!fieldData.option_group_uuid) {
          const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
          this.$data.activeReportableEventFields.participants.push(itemData)
          this.$data.choices.itemsFields.push({
            text: fieldData.label || fieldData.field,
            value: fieldData.field
          })
        }
      }
    }

    // Fill reportFieldsOptions
    if (payload.option_groups && (this.$data.activeReportableEventFields.option_groups === undefined || this.$data.activeReportableEventFields.option_groups.length === 0)) {
      this.$data.activeReportableEventFields.option_groups = []
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.option_groups.push(itemData)
      }
    }

    // Fill reportFieldsLeads
    if (this.$store.state.module?.activatedEventModules?.LEADS.enabled && payload.leads && (this.$data.activeReportableEventFields.leads === undefined || this.$data.activeReportableEventFields.leads.length === 0)) {
      this.$data.activeReportableEventFields.leads = []
      for (const field in payload.leads) {
        const fieldData = payload.leads[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.leads.push(itemData)
        this.$data.choices.itemsFields.push({
          text: fieldData.label || fieldData.field,
          value: fieldData.field
        })
      }
    }

    // Fill reportFieldsParticipantGroup
    if (payload.participant_group && (this.$data.activeReportableEventFields.participant_group === undefined || this.$data.activeReportableEventFields.participant_group.length === 0)) {
      this.$data.activeReportableEventFields.participant_group = []
      for (const field in payload.participant_group) {
        const fieldData = payload.participant_group[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.activeReportableEventFields.participant_group.push(itemData)
      }
    }

    // Fill promoCodes
    if (payload.promo_codes && (this.$data.activeReportableEventFields.promo_codes === undefined || this.$data.activeReportableEventFields.promo_codes.length === 0)) {
      this.$data.activeReportableEventFields.promo_codes = []
      const { code } = payload.promo_codes
      const itemData = { type: code.type, field: code.field, label: 'Promo Code', on_form: code.on_form }
      this.$data.activeReportableEventFields.promo_codes.push(itemData)
      this.$data.choices.itemsFields.push({
        text: 'Promo Code',
        value: code.field
      })
    }

    // Fill allFields
    if (payload.participants && payload.option_groups && this.$data.choices.allFields.length === 0) {
      for (const field in payload.participants) {
        const fieldData = payload.participants[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.choices.allFields.push(itemData)
      }
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
        this.$data.choices.allFields.push(itemData)
      }
      if (this.$store.state.module?.activatedEventModules?.LEADS.enabled) {
        for (const field in payload.leads) {
          const fieldData = payload.leads[field]
          const itemData = { type: fieldData.type, field: fieldData.field, label: fieldData.label, on_form: fieldData.on_form }
          this.$data.choices.allFields.push(itemData)
        }
      }
    }

    // Fill allOptions
    if (payload.option_groups && this.$data.choices.allOptions.length === 0) {
      for (const field in payload.option_groups) {
        const fieldData = payload.option_groups[field]
        for (const optionIndex in fieldData.options) {
          const currentOption = fieldData.options[optionIndex]
          const label = fieldData.label + ': ' + currentOption.name
          const itemData = {
            type: fieldData.type,
            field: fieldData.field,
            value: currentOption.uuid,
            label: label,
            on_form: fieldData.on_form
          }
          this.$data.choices.allOptions.push(itemData)
        }
      }
    }

    this.$data.choices.itemsFields = this.$data.choices.itemsFields.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
  }

  @Watch('initiallySelectedSession')
  setSelectedSession (payload: any) {
    if (payload) {
      this.$data.sessionTable.selected = [payload]
    }
  }

  get initiallySelectedSession () {
    const sessionId = this.report?.session_id
    if (sessionId) {
      return this.sessions?.[sessionId - 1]
    }
    return null
  }

  parseSessionSpeakers (speakers: any[]): string {
    let speakerString = ''
    for (let i = 0; i < speakers.length; i++) {
      speakerString += `${speakers[i].speaker_condensed.first_name} ${speakers[i].speaker_condensed.last_name}`
    }
    return speakerString
  }

  updateFieldSelection (targetFieldList: ReportableEventField[], fields: ReportableEventField[]) {
    targetFieldList.length = 0
    targetFieldList.push(...fields)
  }

  handleExit () {
    this.$router.push({
      name: 'level-two.modules.track.reports',
      params: {
        event_uuid: this.$route.params.event_uuid,
        uuid: this.$route.params.uuid
      }
    })
  }

  async saveReport () {
    try {
      this.$data.submitting = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        data: this.getData()
      }
      if (this.$data.reportEdit.uuid) {
        payload.report_uuid = this.$data.reportEdit.uuid
        await this.$store.dispatch('report/updateReport', payload)
        Container.get(Notification).success('Report successfully updated.')
      } else {
        await this.$store.dispatch('report/createReport', payload)
        Container.get(Notification).success('Report successfully created.')
      }
      this.handleExit()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  private getData () {
    return {
      name: this.$data.reportEdit.name,
      criteria: this.$data.reportEdit.criteria,
      module: this.$data.reportEdit.module,
      report_category: this.$data.reportEdit.report_category,
      report_type: this.$data.reportEdit.report_type,
      export_fields: this.$data.reportEdit.export_fields,
      session_uuid: this.$data.sessionTable.selected[0].uuid
    }
  }

  private async fetchData () {
    try {
      this.$data.loading = true
      const { event_uuid, report_uuid = '' } = this.$route.params
      await Promise.all([
        this.$store.dispatch('option/getOptionsGroup', { event_uuid }),
        this.$store.dispatch('formbuilder/getEventFields', { event_uuid }),
        this.$store.dispatch('formbuilder/getReportableEventFields', { event_uuid }),
        this.$store.dispatch('sessions/fetchSessions', { event_uuid })
      ])
      if (report_uuid) {
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          report_uuid: this.$route.params.report_uuid
        }
        const response = await this.$store.dispatch('report/getReport', payload)
        if (response) {
          if (response.error) {
            this.$data.reportEdit = response.report
          } else {
            this.$data.reportEdit = response
          }
          this.$data.reportEdit.criteria = this.convertOldShowIfToNewFormat(response)
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  areCriteriaReadonly (report: any): boolean {
    if (report.session_activity_id) {
      return true
    }
    return false
  }
}
